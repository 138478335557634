.aboutWrapper {
  display: flex;
  align-items: flex-start;
}

.aboutLeft {
  width: 60%;
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;  
}

.aboutLeft p {
  font-size: min(3.5vw, 1rem);
  color: var(--color-white-medium);
}

.aboutRight {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  border-top: 3px solid var(--color-blue);
  border-right: 3px solid var(--color-blue);
}

.aboutRight img {
  height: 100%;
  width: 100%;
  max-width: 330px;
  margin-left: -10px;
  margin-bottom: -10px;
  padding-top: 10px;
  filter: grayscale(85%);
  cursor: pointer;
}

.aboutRight img:hover {
  filter: grayscale(0%);
}

.imageWrapper:hover {
  border-top: 3px solid var(--color-white);
  border-right: 3px solid var(--color-white);
}

.techStack {
  margin-top: 2rem;
  text-align: left;
  
}

.techStack h4::first-letter {
  color: var(--color-blue);
}

.techStack h4 {
  color: var(--color-white);
  font-weight: bold;
}

ul {
  list-style:     none;
  margin:         0.75em 0;
  padding:        0 1em;
  color: var(--color-white-medium);
}

li:before {
  content:        "";
  border-color:   transparent var(--color-blue);
  border-style:   solid;
  border-width:   0.35em 0 0.35em 0.45em;
  display:        block;
  height:         0;
  left:           -1em;
  position:       relative;
  top:            1.1em;
  width:          0;
}

.typingSpeed {
  font-weight: bold;
  cursor: pointer;
  color: var(--color-blue);
  position: relative;
}


.typingSpeed:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  bottom: -2px;
  left: 0;
  background-color: var(--color-blue);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.typingSpeed:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-width: 991px) {
  .aboutWrapper {
    flex-direction: column;
  }
  .aboutLeft, .aboutRight {
    width: 100%;
    text-align: center;
  }
  .aboutLeft {
    padding: 0;
  }
  .aboutRight {
    margin: 2rem 0 4rem;
  }
  .aboutRight img {
    margin-left: -20px;
    padding-top: -10px;
  }
}

@media screen and (max-height: 800px) {
  .aboutLeft {
    gap: 0;
  }
}