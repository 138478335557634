.leftContainer {
  position: fixed;
  left: 0;
  width: 8rem;
  height: 100%;
  z-index: 1000;

}

.imageContainer {
  position: fixed;
  bottom: 0;
  animation: triangles-animation 1.6s;
}

.imageContainer img {
  width: 8rem;
}

@media screen and (max-width: 768px) {
  .imageContainer img {
    width: 5rem;
  }
}

@keyframes triangles-animation {
  0% {
    opacity: 0%;
  }
  80% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
