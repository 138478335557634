@import url('https://fonts.googleapis.com/css2?family=Epilogue&family=Source+Code+Pro&display=swap');

:root {
    --font-family: 'Source Code Pro', monospace;

    --color-dark: #0D0D1A;

    --color-navbar: #0d0d1ac4;
    --color-white: #fff;
    --color-blue: #2AC4DF;
    --color-white-dark: rgba(170, 170, 170, 0.58);
    --color-white-medium : rgb(219, 219, 219);
    --color-wrong-input: #ce383d;

    /* --color-dark: white;
    --color-white: #0D0D1A;
    --color-blue: #2D9CA6;
    --color-white-dark: rgb(0, 0, 0);
    --color-white-medium : rgb(30, 22, 82); */
}

