.contactWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  /* row-gap: 4.5vh; */
}

.contactWrapper p {
  text-align: center;
  max-width: 55rem;
  margin-bottom: 3rem;
}

.contactForm {
  width: 35rem;
  max-width: 100%;
}

.formInput {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid var(--color-white);
  color: var(--color-white);
}

.formInput:hover {
    border: 1px solid var(--color-blue);
    background-color: transparent;
    box-shadow: none !important;
}

.formInput:focus {
    border: 1px solid var(--color-blue);
    background-color: var(--color-blue);
    box-shadow: none !important;
    font-weight: bold;
    color: var(--color-dark);
}

.formInput:focus::placeholder {
    font-weight: normal;
    color: var(--color-dark);
}

.formInput::placeholder {
  color: var(--color-white-dark);
}

.contactWrapper h2::first-letter {
    color: white!important;
}

.errorMessage {
    color: var(--color-wrong-input)!important;
    font-weight: bold;
}

.emailSentMessage {
    color: var(--color-white)!important;
    animation: 0.5s opacity-animation ease-out;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

@media screen and (max-width: 480px) {
  .contactWrapper p {
    margin-bottom: 1.5rem;
  }
}


@keyframes opacity-animation {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
