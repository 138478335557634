.homeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4.5vh;
}

h1 {
  color: var(--color-white);
  font-weight: bold;
  width: 100%;
  font-size: min(5vw, 4rem);
}

h3 {
  color: var(--color-blue);
  font-weight: bold;
  font-size: min(5vw, 3rem);
}

.homeWrapper p {
  text-align: left;
  max-width: 40rem;
}

h5 {
  color: var(--color-white);
  margin-left: 2px; /*because of how hhuman eye perceives colours */
}


@media screen and (max-width: 768px) {
  .homeWrapper p {
    max-width: 80%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  .homeWrapper {
    gap: 2vh;
  }
  .homeWrapper p {
    width: 80%;
  }
}

@media screen and (max-height: 700px) {
  .homeWrapper {
    gap: 0.8rem;
  }
}