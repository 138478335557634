.rightContainer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 8rem;
  height: 100%;
  animation: triangle-animation 1.4s;
}

@keyframes triangle-animation {
  0% {
    opacity: 0%;
    transform: scale(0.5);
  }
  80% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

.rightSide {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4.5rem;
  height: 58%;
  border-left: 2px solid var(--color-white);
}

.leftSide {
  position: relative;
  top: 0;
  left: 0;
  width: 3rem;
  height: 51%;
  margin-right: 0.5rem;
  border-right: 3px solid var(--color-blue); /* 1px more because object with lighter colour seems bigger by human eye  */
}

.rightTriangle {
  width: 0;
  height: 0;
  border-left: 40px solid var(--color-white);
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  /* move object little bit because of border */
  margin-top: -10px;
  margin-left: -2px;
}

.leftTriangle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-right: 40px solid var(--color-blue);
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  /* move object little bit because of border */
  margin-bottom: -10px;
  margin-right: -3px;
}

.iconsContainer {
  position: absolute;
  bottom: 15vh;
  right: 3.4rem;
  width: 2rem;
  background-color: var(--color-dark);
  padding: 1rem 0;
}

@media screen and (max-height: 730px) {
  .leftSide {
    display: none;
  }

  .rightTriangle {
    display: none;
  }

  .rightSide {
    height: 8rem;
  }

  .iconsContainer {
    bottom: 10vh;
  }
}

@media screen and (max-width: 850px) {
  .rightContainer {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .leftSide {
    display: none;
  }

  .rightTriangle {
    display: none;
  }

  .rightSide {
    height: 8rem;
  }

  .iconsContainer {
    bottom: 10vh;
  }
}
