.navbarContainer {
  top: 1rem;
  padding: 0.6rem 2rem;
  font-family: var(--font-family);
  background-color: var(--color-navbar);
  backdrop-filter: blur(10px);
}


.navbarContainer::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0%;
  border-top: 1px solid var(--color-white);
  border-bottom: 1px solid var(--color-white);
  animation: 0.5s navbar-border-animation;
  z-index: -1;
}


.navbarButtonAnimartion {
  animation: navbar-animation-6 1s;
}

.imageContainer {
  bottom: 0;
  width: 2.6rem;
  float: left;
  animation: navbar-animation-1 1s;
}

.imageContainer img {
  max-width: 100%;
  max-height: 100%;
}

.links {
  /* font-size: 0.9rem; */
  color: var(--color-white-dark) !important;
  margin-right: 1rem;
}

.links:first-child {
  animation: navbar-animation-2 1s;
}

.links:nth-child(2) {
  animation: navbar-animation-3 1s;
}

.links:nth-child(3) {
  animation: navbar-animation-4 1s;
}

.links:last-child {
  margin-right: 4rem;
  animation: navbar-animation-5 1s;
}

.links:hover {
  color: var(--color-white-medium) !important;
}
.links.active {
  color: var(--color-white) !important;
}

.navbarToggle {
  border: none;
  padding: 0;
  animation: 1s navbar-animation-2;
}

.navbarToggle:focus {
  outline: none;
  box-shadow: none;
}

.toggleIcon {
  width: 2rem;
  /* text-align: right; */
}

.toggleIconLine {
  width: 100%;
  margin: 0.5rem 0;
  height: 3px;
  background-color: var(--color-white);
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.toggleIconLine:last-child {
  width: 60%;
  margin-left: auto;
  margin-right: 0;
}

.rotateLeft {
  transform: rotate(135deg);
  margin: 0 0 -0.2rem 0;
}

.rotateRight {
  width: 100% !important;
  transform: rotate(45deg);
  margin: 0;
}

@media screen and (max-width: 992px) {
  .navbarContainer {
    padding: 0.5rem 1rem;
  }
  .navbarContainer {
    position: fixed;
    display: flex;
    width: 100%;
    top: 1rem;
    left: 0;
  }
  .navbarWrapper {
    padding: 0 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .navbarContainer {
    padding: 0.5rem 0.5rem;
  }
  .navbarWrapper {
    padding: 0 1.9rem;
  }
}

@media screen and (max-width: 480px) {
  .navbarContainer {
    padding: 0.5rem 0rem;
  }
  .navbarWrapper {
    padding: 0 1.6rem;
  }
}

@keyframes navbar-border-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes navbar-animation-1 {
  0% {
    opacity: 0%;
    transform: scale(0.5);
  }
  16% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes navbar-animation-2 {
  0% {
    opacity: 0%;
    transform: scale(0.5);
  }
  32% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes navbar-animation-3 {
  0% {
    opacity: 0%;
    transform: scale(0.5);
  }
  48% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes navbar-animation-4 {
  0% {
    opacity: 0%;
    transform: scale(0.5);
  }
  63% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes navbar-animation-5 {
  0% {
    opacity: 0%;
    transform: scale(0.5);
  }
  80% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes navbar-animation-6 {
  0% {
    opacity: 0%;
  }
  96% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

