* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-dark);
}

a {
  color: unset;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--color-dark);
}

::-webkit-scrollbar-thumb {
  border: 1px solid var(--color-white-dark);
}
