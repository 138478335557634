.button {
  border-radius: 0;
  padding: 0.55rem 1.2rem;
  border: 2px solid var(--color-blue);
  border-radius: 8px;
}

.button:active,
.button:focus {
  box-shadow: none !important;
}

.buttonBlank {
  color: var(--color-white);
}

.buttonBlank:hover {
  border: 2px solid var(--color-blue);
  color: var(--color-dark);
  font-weight: bold;
  background-color: var(--color-blue);
}

.buttonFilled {
  background-color: var(--color-blue);
  color: var(--color-dark);
  font-weight: 800;
}

.buttonFilled:hover {
  border: 2px solid var(--color-blue);
  color: var(--color-white);
  font-weight: normal;
  background-color: transparent;
}

.icon {
  margin: 0.5rem 0;
  color: var(--color-white);
  font-size: 1.7rem;
  cursor: pointer;
}

.icon:hover {
  color: var(--color-blue);
}

.technologiesWrapper {
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.technologiesCentered {
  justify-content: center;
}

.technologiesLeft {
  justify-content: flex-start;
}

.technology {
  background-color: var(--color-blue);
  color: var(--color-dark);
  font-weight: bold;
  padding: 0.2rem 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.technologyLeft {
  margin: 1rem 1.5rem 0 0;
}

.technologyCentered {
  margin: 0.5rem;
}

@media screen and (max-width: 500px) {
  .technologiesWrapper {
    justify-content: center;
  }

  .technology {
    font-size: 0.9rem;
    margin: 1rem 0.3rem;
    padding: 0.2rem 0.6rem;
    margin-bottom: 0;

  }
}
