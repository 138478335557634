.contentWrapper {
  width: 100%;
  padding: 0 8rem;
}

@media screen and (max-width: 991px) {
  .contentWrapper {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .contentWrapper {
    padding: 0;
  }
}
