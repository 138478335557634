.workWrapper {
  height: 80vh;
}

.projectWrapper {
  width: 100%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.textSite {
  position: relative;
  width: min(100%, 35rem);
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  z-index: 2;
}

.left {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}

.descriptionWrapper {
  border: 2px solid white;
  padding: 20px;
  background-color: rgba(13, 13, 26, 0.6);
  backdrop-filter: blur(5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.talkWrapper {
  margin: 5rem 0;
  text-align: center;
}

.test {
  width: 100%;
}

.textSite h5 {
  color: var(--color-blue);
  font-weight: bold;
}
.textSite h2 {
  color: var(--color-white);
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.imageSite {
  margin-top: -120px;
}

.imageSiteRight {
  margin-top: -120px;
  display: flex;
  justify-content: flex-end;
}

.imageSite img {
  width: min(80%, 820px);
  filter: brightness(90%);
  border-radius: 20px;
}

@media screen and (max-width: 1300px) {
  .imageSite {
    margin-top: 1rem;
  }

  .imageSite img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .textSite {
    text-align: center;
  }
  .textSite h2 {
    margin-bottom: 1rem;
  }
}
