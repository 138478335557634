.loaderContainer {
  width: 100%;
  height: 100vh;
  background-color: var(--color-dark);
  display: grid;
  justify-content: center;
  align-content: center;
}

.imageWrapper {
  text-align: center;
  opacity: 0;
  animation: 2.5s progress-animation;
}

.loaderContainer img {  
  width: 5rem;
  position: absolute;
  transform: translate(-50%, -50%);
}

.mainLogo {
  animation: 2s opacity-animation;
}

@media screen and (max-width: 480px) {
  .loaderContainer {
    transform: scale(0.75);
  }
}

@keyframes progress-animation {
  0% {
    transform: scale(40%);
    opacity: 100%;
  }
  30% {
    transform: scale(100%);
    opacity: 100%;
  }
  95% {
    opacity: 100%;
    transform: scale(100%);

  }
  100% {
    transform: scale(0%);
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0%;
  }
  40% {
    opacity: 0%;
  }
  60% {
    opacity: 100%;
  }
}
