.contentWrapper {
  width: 100%;
  padding: 2.5rem;
  font-family: var(--font-family);
  scroll-margin-top: 120px;
  min-height: 80vh;
  max-height: 100%;
}

footer {
  font-family: var(--font-family);
  padding: 1.5rem;
  color: var(--color-white-medium);
  text-align: center;
  font-size: min(3.5vw, 1rem);
}

.titleWrapper {
  margin-bottom: 5vh;
}

.titleWrapper h2 {
  font-weight: bold;
  font-size: 30px;
  color: var(--color-white);
}

.titleWrapper h2::first-letter {
  color: var(--color-blue);
}

.iconsContainer {
  visibility: hidden;
  margin-bottom: 0.5rem;
}

.highlight {
  color: var(--color-white);
  font-weight: bold;
  letter-spacing: 1px;
}

p {
  color: var(--color-white);
  display: inline;
  font-size: min(3.5vw, 1rem);
}

@media screen and (max-width: 991px) {
  .contentWrapper {
    padding: 1.7rem;
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    padding: 1.1rem;
  }
}

p {
  color: var(--color-white-medium);
  display: inline;
  font-size: min(3.5vw, 1rem);
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.35rem;
  }
  h5 {
    font-size: 1rem;
  }
  .contentWrapper {
    padding: 0.7rem;
  }
  .iconsContainer {
    visibility: visible;
  }
  footer {
    padding-top: 5rem;
  }
}
